import React from "react";
import "../Assets/css/case.css";
import "../Assets/css/base.css";
import "../Assets/css/demo1.css";
import "../Assets/css/home.css";
import "../Assets/css/contact.css";

import Header from "../components/header";
import Footer from "../components/footer";

class contact extends React.Component {
  render() {
    return (
      <div>
        <Header />

        <div
          data-post-title="makookcase"
          data-theme="theme-dark"
          className="theme-dark"
        >
          <main className="case makookcase">
            <div
              className="background-color-makookcase-1 color-transition"
              data-color="#000"
            >
              <section className="">
                <Footer
                  className="contact-member-item p-t-h"
                  title={"Contact"}
                />
              </section>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default contact;
