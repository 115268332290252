import React from "react";

const Footer = () => (
  <section className="content__section" style={{ margin: "none" }}>
    <div className="content__contact">
      <p className="reach-out">
        Give us a call, or reach out to us via social media. We’re always open
        for a chat.
      </p>
      {/* <p className="reach-out">Ali Benabbes, Owner of Makook.</p> */}
      <div className="content__info">
        <a className="contact--link--footer" href="mailto:hello@makook.space">
          hello@makook.space
        </a>
        <br></br>
        <a className="contact--link--footer" href="tel:+213 550 246249">
          +213 (550) 246249
        </a>
      </div>
      <div className="footer-links ">
        <a
          className="contact--link--footer"
          href="https://www.facebook.com/makook.space"
        >
          Facebook
        </a>
        <a
          className="contact--link--footer"
          href="http://instagram.com/makook.space"
        >
          instagram
        </a>
        <a
          className="contact--link--footer"
          href="https://twitter.com/makookstudio"
        >
          Twitter
        </a>
      </div>
    </div>
  </section>
);

export default Footer;
