import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = () => (
  <div className="Header">
    <div className="HeaderGroup">
      <Link to="/">
        <img
          className="logoimg"
          src={require("../images/makook.svg")}
          alt="alternative img"
        />
      </Link>
      <Link
        className="header-link desktop-only"
        to="/designcode"
        activeClassName="header-link-active"
      >
        DesignCode
      </Link>
      <Link
        className="header-link desktop-only"
        to="/cinematography"
        activeClassName="header-link-active"
      >
        Cinematography
      </Link>

      <Link
        className="header-link"
        to="/studio"
        activeClassName="header-link-active"
      >
        Studio
      </Link>
      <Link
        className="header-link"
        to="/contact"
        activeClassName="header-link-active"
      >
        Contact
      </Link>
    </div>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
